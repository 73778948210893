export const children = {
  name: 'Stem voor kinderen',
  description: 'Hoe scoren politieke partijen op basis van het verkiezingsprogramma?',
  source: 'https://stemvoorkinderen.nl/',
  horizontalAxes: {
    ticks: {
      min: 1,
      max: 5,
      callback: (value) => {
        switch (value) {
          case 1:
            return 'Slecht';
          case 3:
            return 'Matig';
          case 5:
            return 'Goed';
          default:
            return null;
        }
      },
    },
  },
  questions: [
    {
      label: 'Armoede',
      scores: {
        gl: 5,
        pvdd: 4,
        sp: 4,
        pvda: 5,
        d66: 4,
        cu: 5,
        cda: 4,
        vvd: 3,
      }
    },
    {
      label: 'Jeugdzorg',
      scores: {
        gl: 4,
        pvdd: 3,
        sp: 3,
        pvda: 4,
        d66: 5,
        cu: 5,
        cda: 3,
        vvd: 3,
      }
    },
    {
      label: 'Onderwijs',
      scores: {
        gl: 5,
        pvdd: 3,
        sp: 4,
        pvda: 4,
        d66: 5,
        cu: 4,
        cda: 4,
        vvd: 3,
      }
    },
    {
      label: 'Seksuele uitbuiting',
      scores: {
        gl: 4,
        pvdd: null,
        sp: null,
        pvda: 3,
        d66: 4,
        cu: 5,
        cda: 4,
        vvd: 3,
      }
    },
    {
      label: 'Jeugdstrafrecht',
      scores: {
        gl: 4,
        pvdd: null,
        sp: null,
        pvda: 4,
        d66: 4,
        cu: 4,
        cda: 3,
        vvd: 2,
      }
    },
    {
      label: 'Participatie',
      scores: {
        gl: 4,
        pvdd: 4,
        sp: null,
        pvda: 4,
        d66: 4,
        cu: 3,
        cda: 3,
        vvd: null,
      }
    },
    {
      label: 'Discriminatie',
      scores: {
        gl: 4,
        pvdd: 3,
        sp: 3,
        pvda: 4,
        d66: 4,
        cu: 3,
        cda: 2,
        vvd: 2,
      }
    },
    {
      label: ['Ontwikkelings-', 'samenwerking'],
      scores: {
        gl: 3,
        pvdd: 4,
        sp: 3,
        pvda: 4,
        d66: 3,
        cu: 5,
        cda: 2,
        vvd: 1.1,
      }
    },
    {
      label: 'Migratiebeleid',
      scores: {
        gl: 5,
        pvdd: 5,
        sp: 4,
        pvda: 5,
        d66: 4,
        cu: 5,
        cda: 2,
        vvd: 1.1,
      }
    },
  ],
};
