export const climateLabel = {
  name: 'Klimaat label',
  description: 'Het klimaat label is toegekend op basis van stemmingen in de tweede kamer van de afgelopen jaren, met telkens de vraag of de stemming goed of slecht is voor het klimaat.',
  source: 'https://www.klimaatlabelpolitiek.nl/',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 5,
      callback: (value) => {
        switch (value) {
          case 0:
            return 'F';
          case 1:
            return 'E';
          case 2:
            return 'D';
          case 3:
            return 'C';
          case 4:
            return 'B';
          case 5:
            return 'A';
          default:
            return null;
        }
      },
    },
  },
  questions: [
    {
      label: 'Label',
      scores: {
        gl: 5,
        pvdd: 5,
        sp: 4,
        pvda: 4,
        d66: 3,
        cu: 3,
        cda: 2,
        vvd: 1,
      }
    },
  ],
};
