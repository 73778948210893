export const waterNatuurlijk = {
  name: 'Water natuurlijk',
  description: 'Het zijn geen waterschapsverkiezingen, maar nationaal beleid en wetgeving heeft wel gevolgen voor water(schappen).',
  source: 'https://www.waternatuurlijk.nl/geen-categorie/44076/',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 100,
      callback: (value) => `${value}%`,
    },
  },
  questions: [
    {
    label: 'Natuurnetwerk',
      scores: {
        gl: 100,
        d66: 100,
        vvd: 20,
        pvdd: 100,
        pvda: 100,
        sp: 80,
        cu: 100,
        cda: 80,
      }
    }, {
    label: ['Herstel', 'biodiversiteit'],
      scores: {
        gl: 100,
        d66: 100,
        vvd: 80,
        pvdd: 100,
        pvda: 100,
        sp: 80,
        cu: 100,
        cda: 20,
      }
    }, {
    label: 'Aanpak droogte',
      scores: {
        gl: 80,
        d66: 100,
        vvd: 40,
        pvdd: 100,
        pvda: 80,
        sp: 20,
        cu: 100,
        cda: 40,
      }
    }, {
    label: 'Klimaat landelijk',
      scores: {
        gl: 100,
        d66: 100,
        vvd: 80,
        pvdd: 100,
        pvda: 100,
        sp: null,
        cu: 100,
        cda: 80,
      }
    }, {
    label: 'Klimaat stedelijk',
      scores: {
        gl: 100,
        d66: 100,
        vvd: 80,
        pvdd: 80,
        pvda: 80,
        sp: 80,
        cu: 100,
        cda: 100,
      }
    }, {
    label: 'Circulair werken',
      scores: {
        gl: 100,
        d66: 100,
        vvd: 80,
        pvdd: 100,
        pvda: 100,
        sp: 100,
        cu: 80,
        cda: 40,
      }
    }, {
    label: 'Vervuiler betaalt',
      scores: {
        gl: 100,
        d66: 80,
        vvd: 40,
        pvdd: 80,
        pvda: 100,
        sp: 80,
        cu: 80,
        cda: 20,
      }
    }, {
    label: 'Geborgde zetels',
      scores: {
        gl: 100,
        d66: 100,
        vvd: null,
        pvdd: 100,
        pvda: 100,
        sp: null,
        cu: null,
        cda: 20,
      }
    }, {
    label: ['Subsidie lokale', 'partijen'],
      scores: {
        gl: 100,
        d66: null,
        vvd: null,
        pvdd: null,
        pvda: null,
        sp: null,
        cu: 100,
        cda: null,
      }
    }, {
    label: 'Gezond water',
      scores: {
        gl: 100,
        d66: 100,
        vvd: 20,
        pvdd: 100,
        pvda: 100,
        sp: 80,
        cu: 100,
        cda: 40,
      }
    }, {
    label: 'Schoon water',
      scores: {
        gl: 100,
        d66: 100,
        vvd: 80,
        pvdd: 100,
        pvda: 80,
        sp: 100,
        cu: 100,
        cda: 40,
      }
    }, {
      label: ['Ruimte voor', 'water'],
      scores: {
        gl: 80,
        d66: 80,
        pvdd: null,
        pvda: null,
        sp: null,
        cu: 100,
        cda: 80,
      }
    },
  ],
};
