export const fietsers = {
  name: 'Fietsersbond',
  description: 'De Fietsersbond vergeleek de programma’s op tien verschillende onderdelen, gebaseerd op onze inbreng voor de Kamerverkiezingen.',
  source: 'https://www.fietsersbond.nl/nieuws/groenlinks-heeft-beste-programma-voor-de-fiets/',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 3,
      callback: (value) => {
        switch (value) {
          case 0:
            return 'Slecht';
          case 1:
            return 'Matig';
          case 2:
            return 'Voldoende';
          case 3:
            return 'Goed';
          default:
            return null;
        }
      },
    },
  },
  questions: [
    {
      label: 'Score',
      scores: {
        gl: 3,
        pvdd: 3,
        sp: 1,
        pvda: 3,
        d66: 3,
        cu: 3,
        cda: 1,
        vvd: 1,
        volt: 2,
        bijeen: 1
      }
    },
  ],
};
