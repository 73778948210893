import packageJson from '../../package.json';
import Menu from '../fabrique/Menu';
import MenuNavbarItems from '../fabrique/MenuNavbarItems';
import MenuOverlayItem from '../fabrique/MenuOverlayItem';
import MenuOverlayItems from '../fabrique/MenuOverlayItems';
import MenuOverlaySection from '../fabrique/MenuOverlaySection';

const MenuOverlay = () => (
  <MenuOverlayItems>
    <MenuOverlaySection type="large">
      <MenuOverlayItem href="/" label="Home" />
      <MenuOverlayItem href="/standpunten" label="Standpunten" />
      <MenuOverlayItem href="/kom-in-actie" label="Kom in actie" />
    </MenuOverlaySection>
    <MenuOverlaySection type="regular">
      <MenuOverlayItem href="https://groenlinks.nl/onze-mensen" label="Onze mensen" />
      <MenuOverlayItem href="https://groenlinks.nl/afdeling" label="Onze afdeling" />
      <MenuOverlayItem href="https://groenlinks.nl/nieuws" label="Nieuws" />
      <MenuOverlayItem href="https://groenlinks.nl/agenda" label="Agenda" />
      <MenuOverlayItem href="https://groenlinks.nl" label="Naar GroenLinks.nl" />
    </MenuOverlaySection>
    <MenuOverlaySection type="footer">
      <MenuOverlayItem href="https://mijn.groenlinks.nl" label="Mijn GroenLinks" />
    </MenuOverlaySection>
  </MenuOverlayItems>
);

const MenuNavbarLeft = ({ setOverlay }) => (
  <MenuNavbarItems side="left" >
  </MenuNavbarItems>
)

const MenuNavbarRight = () => (
  <MenuNavbarItems side="right" >
  </MenuNavbarItems>
)

const AppMenu = () => (
  <Menu
    menuOverlay={MenuOverlay}
    menuNavbarLeft={MenuNavbarLeft}
    menuNavbarRight={MenuNavbarRight}
    websiteName={packageJson.name}
  />
);

export default AppMenu;
