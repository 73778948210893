export const fossileFree = {
  name: 'Reclame Fossielvrij',
  description: 'Welke partijen hebben het lef om \'NEE\' te zeggen tegen de fossiele industrie? ',
  source: 'https://www.facebook.com/ReclameFossielvrij/photos/a.113083150231977/287278919479065/',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 3,
      callback: (value) => {
        switch (value) {
          case 0:
            return 'Slecht';
          case 1:
            return 'Matig';
          case 2:
            return 'Goed';
          default:
            return null;
        }
      },
    },
  },
  questions: [
    {
      label: ['Fossiele subsidies', 'afschaffen'],
      scores: {
        gl: 2,
        d66: 2,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 2,
        sp: 2,
        cu: 2,
        volt: 2,
        bijeen: 2,
      }
    },
    {
      label: ['Verbod op fossiele', 'reclames'],
      scores: {
        gl: 2,
        d66: 0.1,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 2,
        sp: 0.1,
        cu: 2,
        volt: 0.1,
        bijeen: 2,
      }
    },
    {
      label: ['Pensioenfondsen en banken', 'moeten desinvesteren'],
      scores: {
        gl: 2,
        d66: 0.1,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 2,
        sp: 0.1,
        cu: 0.1,
        volt: 0.1,
        bijeen: 2,
      }
    },
    {
      label: ['Geen boringen in', 'de Waddenzee'],
      scores: {
        gl: 2,
        d66: 0.1,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 2,
        sp: 2,
        cu: 2,
        volt: 0.1,
        bijeen: 2,
      }
    },
    {
      label: ['Verplicht klimaat & mensen-', 'rechten voor bedrijven'],
      scores: {
        gl: 2,
        d66: 0.1,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 2,
        sp: 2,
        cu: 2,
        volt: 0.1,
        bijeen: 2,
      }
    },
    {
      label: ['Lobbyregister om fossiele', 'lobby tegen te gaan'],
      scores: {
        gl: 2,
        d66: 0.1,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 0.1,
        sp: 2,
        cu: 0.1,
        volt: 0.1,
        bijeen: 2,
      }
    },
    {
      label: ['Afbouwen kolen-, olie-', 'en gasindustrie'],
      scores: {
        gl: 2,
        d66: 1,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 1,
        sp: 0.1,
        cu: 1,
        volt: 0.1,
        bijeen: 2,
      }
    },
    {
      label: ['Einde aan bezine-', 'en dieselauto'],
      scores: {
        gl: 2,
        d66: 1,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 2,
        sp: 0.1,
        cu: 0.1,
        volt: 0.1,
        bijeen: 0.1,
      }
    },
    {
      label: 'Luchtvaart moet krimpen',
      scores: {
        gl: 2,
        d66: 0.1,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 1,
        sp: 0.1,
        cu: 2,
        volt: 0.1,
        bijeen: 1,
      }
    },
    {
      label: ['Geen teerzand en', 'schaliegas importeren'],
      scores: {
        gl: 0.1,
        d66: 0.1,
        vvd: 0.1,
        cda: 0.1,
        pvdd: 2,
        pvda: 0.1,
        sp: 0.1,
        cu: 0.1,
        volt: 0.1,
        bijeen: 0.1,
      }
    },
  ],
};
