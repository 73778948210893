export const pbl = {
  name: 'Planbureau voor de leefomgeving',
  description: 'Doorrekening verkiezingsprogramma\'s op effecten Klimaat & Energie.',
  source: 'https://www.pbl.nl/sites/default/files/downloads/pbl-2021-analyse-leefomgevingseffecten-verkiezingsprogrammas-2021-2025-4324_0.pdf',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 100,
      callback: (value) => `${value}%`
    },
  },
  questions: [
    {
      label: ['CO2-reductie', 'in 2030'],
      scores: {
        gl: 63,
        pvdd: null,
        sp: 53,
        pvda: 55,
        d66: 60,
        cu: 52,
        cda: 46,
        vvd: 41,
      }
    },
  ],
};
