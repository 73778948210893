export const gender = {
  name: 'Gendergelijkheid Stemadvies',
  description: 'Met Het Gendergelijkheid Stemadvies van WOMEN Inc. en Atria kun je zien je in hoeverre politieke partijen aan de slag gaan met gendergelijkheid.',
  source: 'https://www.stemgendergelijkheid.nl/',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 2,
      callback: (value) => {
        switch (value) {
          case 0:
            return 'Rood';
          case 1:
            return 'Oranje';
          case 2:
            return 'Groen';
          default:
            return null;
        }
      },
    },
  },
  questions: [
    {
      label: 'Loonkloof',
      scores: {
        bijeen: 2,
        volt: 2,
        cda: 1,
        cu: 2,
        d66: 2,
        gl: 2,
        pvda: 2,
        pvdd: 1,
        sp: 1.5,
        vvd: 0.1,
      }
    },
    {
      label: 'Werk-zorg verdeling',
      scores: {
        bijeen: 2,
        volt: 2,
        cda: 2,
        cu: 2,
        d66: 2,
        gl: 2,
        pvda: 2,
        pvdd: 2,
        sp: 2,
        vvd: 1,
      }
    },
    {
      label: 'Gezondheidszorg',
      scores: {
        bijeen: 2,
        volt: 0.1,
        cda: 0.1,
        cu: 2,
        d66: 2,
        gl: 2,
        pvda: 2,
        pvdd: 2,
        sp: 0.1,
        vvd: 0.1,
      }
    },
    {
      label: ['Onderwijs en', 'beeldvorming'],
      scores: {
        bijeen: 2,
        volt: 0.1,
        cda: 0.1,
        cu: 1,
        d66: 1,
        gl: 2,
        pvda: 2,
        pvdd: 2,
        sp: 0.1,
        vvd: 2,
      }
    },
    {
      label: ['Besluitvormende', 'posities'],
      scores: {
        bijeen: 1,
        volt: 1,
        cda: 2,
        cu: 0.1,
        d66: 2,
        gl: 2,
        pvda: 2,
        pvdd: 0.1,
        sp: 0.1,
        vvd: 0.1,
      }
    },
    {
      label: 'Overheid',
      scores: {
        bijeen: 2,
        volt: 2,
        cda: 1,
        cu: 1,
        d66: 2,
        gl: 2,
        pvda: 2,
        pvdd: 1,
        sp: 1,
        vvd: 0.1,
      }
    },
    {
      label: ['Gendergerelateerd', 'geweld'],
      scores: {
        bijeen: 2,
        volt: 2,
        cda: 1,
        cu: 1,
        d66: 1,
        gl: 2,
        pvda: 2,
        pvdd: 1,
        sp: 2,
        vvd: 1,
      }
    },
    {
      label: ['Seksuele en', 'reproductieve rechten'],
      scores: {
        bijeen: 2,
        volt: 2,
        cda: 1,
        cu: 1,
        d66: 2,
        gl: 2,
        pvda: 2,
        pvdd: 1,
        sp: 0.1,
        vvd: 1,
      }
    },
  ],
};


