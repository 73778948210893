export const meetlat = {
  name: 'BNNVARA Vroege Vogels',
  description: 'Vroege Vogels legt de lijsttrekkers langs de Groene Meetlat.',
  source: 'https://www.bnnvara.nl/vroegevogels/artikelen/politieke-partijen-langs-de-groene-meetlat',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 10,
      callback: (val) => 11 - val,
    },
  },
  questions: [
    {
      label: 'Klimaat',
      scores: {
        gl: 10,
        pvdd: 9,
        sp: 6,
        pvda: 5,
        d66: 8,
        cu: 7,
        cda: 1,
        vvd: 2,
      }
    },
    {
      label: 'Natuur',
      scores: {
        gl: 9,
        pvdd: 10,
        sp: 3,
        pvda: 6,
        d66: 8,
        cu: 7,
        cda: 5,
        vvd: 1,
      }
    },
    {
      label: 'Dierenwelzijn',
      scores: {
        gl: 9,
        pvdd: 10,
        sp: 8,
        pvda: 4,
        d66: 6,
        cu: 3,
        cda: 0.1,
        vvd: 1,
      }
    },
  ],
};
