import React from 'react';

import RichText from './RichText';
import ShareButtons from './ShareButtons';

const Header = ({
  children,
  image,
  label,
  share,
  shareLabel,
  small,
}) => {
  let headerType = 'header--default';
  if (image) {
    headerType = small ? 'header--image-small' : 'header--image';
  }
  return (
    <div className={`header ${headerType}`}>
      {image && (
        <div className="header__image-wrapper">
          <picture className={`picture ${small ? '' : 'picture--background'}`}>
            <img
              alt={label}
              src={image}
              className=" picture__image"
              loading="lazy"
            />
          </picture>
        </div>
      )}
      <div className="header__grid">
        {label && (
          <div className="header__title-container">
            <h1 className="header__title">
              <span className="header__title-background">{label}</span>
            </h1>
          </div>
        )}
        {share && (shareLabel || label) && (
          <div className="header__share-buttons">
            <ShareButtons label={shareLabel || label} href={window.location.href} />
          </div>
        )}
        {children && (
          <div className="header__introduction">
            <RichText type="introduction">
              {children}
            </RichText>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
