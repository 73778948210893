export const mental = {
  name: 'GGZ Kieswijzer',
  description: 'Hoe denken de politieke partijen over een aantal belangrijke thema’s binnen de geestelijke gezondheids- en verslavingszorg?',
  source: 'https://www.kiesvoorggz.nl/',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 6,
    },
  },
  questions: [
    {
      label: 'Wachtlijsten',
      scores: {
        volt: 6,
        gl: 6,
        pvdd: 3,
        sp: 6,
        pvda: 6,
        d66: 6,
        cu: 4,
        cda: 5,
        vvd: 6,
      }
    },
    {
      label: 'Jeugd-GGZ',
      scores: {
        volt: 6,
        gl: 6,
        pvdd: 3,
        sp: 5,
        pvda: 6,
        d66: 6,
        cu: 4,
        cda: 4,
        vvd: 6,
      }
    },
    {
      label: 'Ambulantisering',
      scores: {
        volt: 4,
        gl: 5,
        pvdd: 3,
        sp: 4,
        pvda: 4,
        d66: 3,
        cu: 5,
        cda: 5,
        vvd: 6,
      }
    },
    {
      label: 'Ervaringsdeskundigheid',
      scores: {
        volt: 4,
        gl: 5,
        pvdd: 3,
        sp: 4,
        pvda: 6,
        d66: 4,
        cu: 2,
        cda: 3,
        vvd: 6,
      }
    },
    {
      label: 'Onderzoek en innovatie',
      scores: {
        volt: 5,
        gl: 5,
        pvdd: 3,
        sp: 4,
        pvda: 5,
        d66: 5,
        cu: 4,
        cda: 4,
        vvd: 6,
      }
    },
    {
      label: 'Arbeidsparticipatie',
      scores: {
        volt: 4,
        gl: 6,
        pvdd: 3,
        sp: 4,
        pvda: 5,
        d66: 6,
        cu: 4,
        cda: 4,
        vvd: 6,
      }
    },
    {
      label: '(de)stigmatisering',
      scores: {
        volt: 5,
        gl: 6,
        pvdd: 3,
        sp: 4,
        pvda: 6,
        d66: 6,
        cu: 3,
        cda: 3,
        vvd: 6,
      }
    },
    {
      label: 'Ouderenpsychiatrie',
      scores: {
        volt: 4,
        gl: 4,
        pvdd: 3,
        sp: 4,
        pvda: 5,
        d66: 4,
        cu: 3,
        cda: 3,
        vvd: 6,
      }
    },
    {
      label: 'Kwaliteit van de GGZ',
      scores: {
        volt: 4,
        gl: 6,
        pvdd: 3,
        sp: 6,
        pvda: 6,
        d66: 5,
        cu: 2,
        cda: 5,
        vvd: 6,
      }
    },
    {
      label: 'Toegankelijkheid',
      scores: {
        volt: 5,
        gl: 6,
        pvdd: 3,
        sp: 6,
        pvda: 6,
        d66: 5,
        cu: 3,
        cda: 4,
        vvd: 6,
      }
    },
    {
      label: ['Jongeren en mentale', 'gezondheid op school'],
      scores: {
        volt: 5,
        gl: 6,
        pvdd: 3,
        sp: 4,
        pvda: 6,
        d66: 6,
        cu: 4,
        cda: 3,
        vvd: 6,
      }
    },
    {
      label: 'Lotgenotencontact',
      scores: {
        volt: 4,
        gl: 4,
        pvdd: 3,
        sp: 4,
        pvda: 5,
        d66: 4,
        cu: 2,
        cda: null,
        vvd: 6,
      }
    },
    {
      label: 'Preventie',
      scores: {
        volt: 5,
        gl: 5,
        pvdd: 3,
        sp: 6,
        pvda: 6,
        d66: 6,
        cu: 6,
        cda: 4,
        vvd: 6,
      }
    },
  ],
};
