import React from 'react';

const Button = ({
  active,
  disabled,
  href,
  label,
  onClick,
  type,
}) => {
  const className = [
    'button',
    `button--${type}`,
    active ? 'button--selected' : '',
    disabled ? 'button--disabled' : ''
  ].join(' ');

  return (
    <a className={className} href={href} aria-label={label} onClick={onClick}>
      <span className="button__span">{label}</span>
    </a>
  );
}

Button.defaultProps = {
  type: 'secondary'
}

export default Button;
