export const actionAid = {
  name: 'ActionAid',
  description: 'Tijdens de aankomende verkiezingen roepen wij politieke leiders op om vanuit feministische waarden te regeren en aan de volgende punten gehoor te geven.',
  source: 'https://www.actionaid.nl/wp-content/uploads/2021/02/ActionAid_FeministischePartijwijzer_Online_Highres.pdf',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 2,
      callback: (value) => {
        switch (value) {
          case 0:
            return 'Slecht';
          case 1:
            return 'Matig';
          case 2:
            return 'Goed';
          default:
            return null;
        }
      },
    },
  },
  questions: [
    {
      label: 'Intersectionaliteit',
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 1,
        d66: 2,
        sp: 2,
        cu: 1,
        cda: 1,
        vvd: 0.1,
      }
    },
    {
      label: 'Vrouwenrechten internationaal',
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 0.1,
        gl: 1,
        d66: 2,
        sp: 0.1,
        cu: 1,
        cda: 1,
        vvd: 0.1,
      }
    },
    {
      label: 'Vrouwenrechten dwarsdoorsnijdend',
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 2,
        d66: 1,
        sp: 0.1,
        cu: 1,
        cda: 1,
        vvd: 1,
      }
    },
    {
      label: 'Aanpak belastingontwijking',
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 2,
        d66: 1,
        sp: 0.1,
        cu: 2,
        cda: 1,
        vvd: 0.1,
      }
    },
    {
      label: ['Aandacht voor ontwikkelingslanden', 'in belastingplannen'],
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 2,
        d66: 2,
        sp: 2,
        cu: 2,
        cda: 0.1,
        vvd: 0.1,
      }
    },
    {
      label: 'Invoering digitale belastingen',
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 1,
        gl: 2,
        d66: 2,
        sp: 2,
        cu: 2,
        cda: 2,
        vvd: 2,
      }
    },
    {
      label: ['Klimaatplannen gericht op', 'maximaal 1,5 graad opwarming'],
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 2,
        d66: 1,
        sp: 1,
        cu: 1,
        cda: 1,
        vvd: 0.1,
      }
    },
    {
      label: ['Erkenning van extra verantwoordelijkheid', 'klimaatcrisis globale noorden'],
      scores: {
        pvdd: 2,
        pvda: 1,
        bijeen: 2,
        gl: 2,
        d66: 1,
        sp: 1,
        cu: 0.1,
        cda: 0.1,
        vvd: 0.1,
      }
    },
    {
      label: 'Klimaatfinanciering',
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 2,
        d66: 1,
        sp: 1,
        cu: 1,
        cda: 0.1,
        vvd: 0.1,
      }
    },
    {
      label: 'Verlies en schade',
      scores: {
        pvdd: 0.1,
        pvda: 1,
        bijeen: 2,
        gl: 0.1,
        d66: 0.1,
        sp: 1,
        cu: 0.1,
        cda: 0.1,
        vvd: 0.1,
      }
    },
    {
      label: 'Landrechten',
      scores: {
        pvdd: 0.1,
        pvda: 0.1,
        bijeen: 1,
        gl: 0.1,
        d66: 0.1,
        sp: 0.1,
        cu: 0.1,
        cda: 0.1,
        vvd: 0.1,
      }
    },
    {
      label: ['Promotie voedselzekerheid voor', 'vrouwen en gemeenschappen'],
      scores: {
        pvdd: 2,
        pvda: 1,
        bijeen: 1,
        gl: 1,
        d66: 2,
        sp: 0.1,
        cu: 1,
        cda: 1,
        vvd: 0.1,
      }
    },
    {
      label: ['Promotie kleinschalige duurzame landbouw', 'en transitie uit industriële landbouw'],
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 2,
        d66: 1,
        sp: 2,
        cu: 2,
        cda: 0.1,
        vvd: 0.1,
      }
    },
    {
      label: ['Wetgeving om', 'mensenrechtenschendingen en'],
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 2,
        d66: 2,
        sp: 2,
        cu: 2,
        cda: 0.1,
        vvd: 0.1,
      }
    },
    {
      label: 'Stop investering in fossiele brandstof',
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 2,
        d66: 1,
        sp: 2,
        cu: 0.1,
        cda: 0.1,
        vvd: 1,
      }
    },
    {
      label: 'Circulair grondstoffen gebruiken',
      scores: {
        pvdd: 2,
        pvda: 2,
        bijeen: 2,
        gl: 2,
        d66: 2,
        sp: 2,
        cu: 2,
        cda: 1,
        vvd: 1,
      }
    },
  ],
};








