import React from 'react';

import PageWrapper from '../fabrique/PageWrapper';
import HomePage from '../routes/HomePage';

import AppMenu from './AppMenu';

const App = () => (
  <PageWrapper>
    <AppMenu />
    <HomePage />
  </PageWrapper>
);

export default App;
