export const vluchtelingen = {
  name: 'Vluchtelingenwerk',
  description: 'Hoe vluchteling-vriendelijk zijn de partijen?',
  source: 'https://www.vluchtelingenwerk.nl/maakgeenverschil/',
  horizontalAxes: {
    ticks: {
      min: 0,
      max: 100,
      callback: (value) => `${value}%`,
    },
  },
  questions: [
    {
      label: 'Score',
      scores: {
        gl: 100,
        pvdd: 90,
        sp: 90,
        pvda: 90,
        d66: 90,
        cu: 95,
        cda: 50,
        vvd: 10,
      }
    },
  ],
};
