import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import Button from '../fabrique/Button';
import Content from '../fabrique/Content';
import Header from '../fabrique/Header';
import Main from '../fabrique/Main';
import { actionAid } from '../helpers/actionAid';
import { children } from '../helpers/children';
import { climateLabel } from '../helpers/climateLabel';
import { fietsers } from '../helpers/fietsers';
import { fossileFree } from '../helpers/fossileFree';
import { gender } from '../helpers/gender';
import { meetlat } from '../helpers/meetlat';
import { mental } from '../helpers/mental';
import { pbl } from '../helpers/pbl';
import { vluchtelingen } from '../helpers/vluchtelingen';
import { waterNatuurlijk } from '../helpers/waterNatuurlijk';

const sources = [
  pbl,
  climateLabel,
  fietsers,
  vluchtelingen,
  meetlat,
  gender,
  fossileFree,
  children,
  waterNatuurlijk,
  actionAid,
  mental,
];

const parties = {
  gl: {
    label: 'GroenLinks',
    color: '#39a935'
  },
  pvda: {
    label: 'PvdA',
    color: '#e8070f',
  },
  sp: {
    label: 'SP',
    color: '#EC1B23',
  },
  d66: {
    label: 'D66',
    color: '#028a5b',
  },
  pvdd: {
    label: 'PvdD',
    color: '#01462f',
  },
  cu: {
    label: 'ChristenUnie',
    color: '#00abec',
  },
  cda: {
    label: 'CDA',
    color: '#3c415d'
  },
  vvd: {
    label: 'VVD',
    color: '#f77b08'
  },
  bijeen: {
    label: 'BIJ1',
    color: '#fefe00'
  },
  volt: {
    label: 'Volt',
    color: '#4e2277',
  },
};

const HomePage = () => {
  const [selectedParty, setSelectedParty] = React.useState(
    Object.keys(parties).find((party) => party === window.location.href.split('#')[1])
  );

  return (
    <React.Fragment>
      <Header
        label="Vergelijk partijen"
      >
        Wij hopen natuurlijk dat je op GroenLinks gaat stemmen. Maar wat zeggen onafhankelijke organisaties over ons programma? Vergelijk hier onze plannen met die van andere partijen.
      </Header>
      <Main>
        <div className="party-buttons">
          {Object.keys(parties).filter((party) => party !== 'gl').map((party) => (
            <Button
              active={selectedParty === party}
              onClick={() => {
                const location = new URL(window.location.href);
                location.hash = party;
                window.history.replaceState({}, document.title, location);
                setSelectedParty(party);
              }}
              label={parties[party].label}
            />
          ))}
        </div>
        <Content>
          {!selectedParty && (
            <p style={{ marginBottom: '2em' }}>Selecteer een partij om te vergelijken.</p>
          )}
          {selectedParty && sources.map((source) => (
            <Source
              key={`${source.name}`}
              selectedParty={selectedParty}
              {...source}
            />
          ))}
          {(selectedParty === 'volt' || selectedParty === 'bijeen') && <div className="partial-notice">Deze partij is niet meegenomen in alle kieswijzers van de organisaties die die hebben opgesteld.</div>}
        </Content>
      </Main>
    </React.Fragment>
  );
}

const Source = ({
  description,
  horizontalAxes,
  name,
  source,
  questions,
  selectedParty,
}) => {
  const [open, setOpen] = React.useState(true);
  const options = React.useMemo(
    () => ({
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {display: false},
          ticks: horizontalAxes.ticks,
        }],
        yAxes: [{
          gridLines: {display: false}
        }]
      },
      tooltips: {
        enabled: false
      }
    }),
    [horizontalAxes.format]
  );
  const filter = (question) => (
    question.scores[selectedParty] !== null && typeof question.scores[selectedParty] !== 'undefined'
  );

  const data = React.useMemo(
    () => {
      return {
        labels: questions.map((question) => question.label),
        datasets: ['gl', selectedParty].filter(Boolean).map((party) => ({
          barThickness: 15,
          label: parties[party].label,
          backgroundColor: parties[party].color,
          data: questions.map((question) => filter(question) ? question.scores[party] : null),
        }))
      }
    },
    [questions, selectedParty]
  );

  if (!selectedParty) {
    return null;
  }

  if (questions.filter(filter).length === 0) {
    return null;
  }

  return (
    <div class="source">
      <label>
        <a target="_blank" href={source}>{name}</a>
        <button onClick={() => setOpen(!open)}>{open ? 'Verberg' : 'Toon'}</button>
      </label>
      <div>{description}</div>
      {name === 'Planbureau voor de leefomgeving' && selectedParty === 'vvd' && (
        <div className="ggz-notice">Omdat de VVD haar programma niet heeft laten doorrekenen, hebben we <a href="https://groenlinks.nl/sites/groenlinks/files/2021-03/Doorrekening_Programma_VVD_Klimaat%26Energie_door_Kalavasta.pdf" target="_blank">Kalavasta</a> gevraagd dat voor ons te doen.</div>
      )}
      {name === 'GGZ Kieswijzer' && selectedParty === 'vvd' && (
        <div className="ggz-notice">Partijen mochten deze beloftes zelf invullen. Daar hebben wij bij de antwoorden van de VVD over de ggz en jeugdzorg wel <a href="https://twitter.com/jesseklaver/status/1360695120383868935" target="_blank">een mening over</a>.</div>
      )}
      <div
        class={open ? 'source-bar source-bar-open' : 'source-bar source-bar-closed'}
        style={{height: open ? `${48 + data.labels.length * 45}px` : '0px'}}
      >
        <HorizontalBar data={data} options={options} />
        {name === 'GGZ Kieswijzer' && selectedParty === 'vvd' && <GGZEmbed />}
      </div>
    </div>
  );
}

const GGZEmbed = () => {
  React.useLayoutEffect(() => {
    window?.twttr?.widgets?.load();
  }, []);

  return (
    <div className="twitter-wrapper">
      <blockquote className="twitter-tweet">
        <p lang="nl" dir="ltr">Onder VVD-beleid liepen de wachtlijsten op tot recordhoogte, werd de ggz overgelaten aan de markt en menig plan voor herstel weggestemd.<br /><br />En dan nu jezelf in deze stemwijzer op alles het maximale laten scoren? Schaamteloos.
          <a href="https://t.co/Z3EKoD3C8i">https://t.co/Z3EKoD3C8i</a>
          <a href="https://t.co/pKrwS12VFK">pic.twitter.com/pKrwS12VFK</a>
        </p>&mdash; Jesse Klaver (@jesseklaver) <a href="https://twitter.com/jesseklaver/status/1360695120383868935?ref_src=twsrc%5Etfw">February 13, 2021</a>
      </blockquote>
    </div>
  )
}
export default HomePage;
